<template>
  <b-card>
    <!-- about -->
    <h3 class="mb-2">
      Daftar Perubahan
    </h3>
    <app-timeline>
      <app-timeline-item>
        <h6>{{ detailProgram.update_ts | formatDate }}</h6>
        <b-button
          variant="outline-info"
          size="sm"
        >
          View Detail
        </b-button>
      </app-timeline-item>
      <app-timeline-item>
        <h6>{{ detailProgram.update_ts | formatDate }}</h6>
        <b-button
          variant="outline-info"
          size="sm"
        >
          View Detail
        </b-button>
      </app-timeline-item>
      <app-timeline-item>
        <h6>{{ detailProgram.update_ts | formatDate }}</h6>
        <b-button
          variant="outline-info"
          size="sm"
        >
          View Detail
        </b-button>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    detailProgram: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
